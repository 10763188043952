import React from "react";
import "./Home.css"
import img_1 from "../images/roof.jpg";
import wall from "../images/wallcadding.jpeg"
import zin from "../images/zincalume.jpeg";
import smart from "../images/smartbuilding.jpeg";
import structure from "../images/structuralsolution.jpeg"
import butler from "../images/butlerbuilding.jpeg"
import coated from "../images/colourcoated.jpeg"
import solar from "../images/solarframe.jpeg"
import sheet from "../images/decking sheet.jpeg"
import ventilator from "../images/ventilator.jpeg"
import sheets from "../images/curved.jpeg"
import cad from "../images/wallcd.jpeg"
import bsnl from "../images/bsnl.png"
import mahe from "../images/mahe.png"
import nissan from "../images/nissan.png"
import tata from "../images/tata.png"
import finance from "../images/img1.png"
import 'bootstrap/dist/css/bootstrap.min.css';
function Home() {
    return(
    <>


<div className="container-fluid">
        <div className="row position-relative">
          <img src={img_1} className="img-fluid" alt="Background" />
          <div className="position-absolute top-50 start-50 mt-5 translate-middle text-overlay text-center">
            <h1 className="text-dark">We Manufacturing <br/>industrial steel<br/>roof top sheet</h1>
            <button id="summa" className="btn btn-warning text-white">Learn More</button>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-5">
        <h1 className="text-center">Our services</h1>
        </div>

      </div>
      <div className="container-fluid">
        <div className="row justify-content-between text-center mt-3">
          <div className="col-lg-1">
         &nbsp;

  </div>
   <div className="col-lg-3 sim">
          <img className="p-3" src={wall} style={{width:"320px", height:"300px"}}/>
          <h4 id="sim2">WALL CADDING</h4>

  </div>
   <div className="col-lg-3 p-2 sim">
          <img src={zin} style={{width:"320px", height:"270px"}}/>
 <h4 id="sim2" className="mt-4">ZINCALUME</h4>
  </div>
   <div className="col-lg-3 p-2 sim">
          <img src={smart} style={{width:"320px", height:"300px"}}/>
 <h4 id="sim2">SMART BUILDING</h4>
  </div>
   <div className="col-lg-1">
         &nbsp;

  </div>
        </div>

      </div>
      <div className="container-fluid">
        <div className="row justify-content-between text-center mt-3">
          <div className="col-lg-1">
         &nbsp;

  </div>
   <div className="col-lg-3 sim">
          <img className="p-3" src={structure} style={{width:"320px", height:"300px"}}/>
          <h4 id="sim2">WALL CADDING</h4>

  </div>
   <div className="col-lg-3 p-2 sim">
          <img src={butler} style={{width:"320px", height:"270px"}}/>
 <h4 id="sim2" className="mt-4">ZINCALUME</h4>
  </div>
   <div className="col-lg-3 p-2 sim">
          <img src={coated} style={{width:"320px", height:"300px"}}/>
 <h4 id="sim2">SMART BUILDING</h4>
  </div>
   <div className="col-lg-1">
         &nbsp;

  </div>
        </div>

      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 text-center mt-5">
        <button className="text-center sim4 p-3">View All Services</button>
          </div>
         
        </div>

      </div>
      <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-lg-12 text-center bg-secondary sim5">
          <h4 id="sim6">Hari Om Roofing Industries is one of the leading and the largest private<br/>
sector producer of roofing sheets in India.</h4>

        </div>

      </div>
      </div>
      <div className="container-fluid">
        <div className="row  mt-3">
          <div className="col-lg-3">
&nbsp;
          </div>
          <div className="col-lg-6 text-center sim8">
            <h2>Why Choose Us</h2>
            <p style={{fontSize:"20px"}}>Hari Om Roofing Industries foundied by chairman Mr H Harish.<br/>
Our range of pre-engineered buildings, roofing, cladding and rainwater<br/>
systems, made from raw materials of the highest quality in accordance<br/>
with strict ASTM / JIS / AS and IS standards, offers flexibility of design to<br/>
suit any construction need be it your business, factory, home or office.<br/>
Our products are made to meet your aesthetic needs as well.</p>

          </div>
          <div className="col-lg-3">
&nbsp;
          </div>

        </div>

      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 text-center mt-5">
            <h2>OUR PROJECTS</h2>

          </div>

        </div>

      </div>
      <div className="container-fluid">

        <div className="row text-center gap-2">
          <div className="col-lg-1">
            &nbsp;

          </div>
          <div className="col-lg-2 sim9 p-2">
          <img src={solar} className="img-fluid" style={{height:"300px", width:"200px"}}/>
          <h4 id="sim10">WALL CADDING</h4>
          </div>
          <div className="col-lg-2 sim9 p-2">
          <img src={sheet} className="img-fluid" style={{height:"300px", width:"200px"}}/>
          <h4 id="sim10">WALL CADDING</h4>
          </div>
          <div className="col-lg-2 sim9 p-2">
          <img src={ventilator} className="img-fluid" style={{height:"300px", width:"200px"}}/>
          <h4 id="sim10">WALL CADDING</h4>
          </div>
          <div className="col-lg-2 sim9 p-2">
          <img src={sheets} className="img-fluid" style={{height:"300px", width:"200px"}}/>
          <h4 id="sim10">WALL CADDING</h4>
          </div>
          <div className="col-lg-2 sim9 p-2">
          <img src={cad} className="img-fluid"style={{height:"300px", width:"200px"}}/>
          <h4 id="sim10">WALL CADDING</h4>
          </div>
          <div className="col-lg-1">
            &nbsp;

          </div>
        </div>

      </div>
      <div className="container-fluid">
          
          <div className="row">
            <div className="col-lg-12 text-center mt-5">
              <h2>TESTIMONIALS
</h2>

            </div>

          </div>
      </div>
      <div className="container-fluid">
        <div className="row gap-5 justify-content-center  text-center">
          
          <div className="col-lg-5 bg-dark text-white p-4">

         <p className="mt-4">Many thanks for your roofing system which saved my customers at<br/>
parking.we appreciate your quality concern methods!</p>

          </div>
          <div className="col-lg-5 bg-dark text-white p-4">

<p className="mt-4">Many thanks for your roofing system which saved my customers at<br/>
parking.we appreciate your quality concern methods!</p>

 </div>
 



        </div>

      </div>
      <div className="container-fluid">
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-2">
            &nbsp;

          </div>
          <div className="col-lg-1">
            <h1>1100+</h1>
<div className="sim11 p-1">

</div>
<h2 className="mt-4 sim12">Clients</h2>

          </div>
          <div className="col-lg-1">
            &nbsp;

          </div>
          <div className="col-lg-2">
            <h1>2000+

</h1>


<p className="sim11 p-1">

</p>
<h2 className="mt-4 sim12">Project</h2>

          </div>
          
        
          <div className="col-lg-3">
            <h1>10+

</h1>
<p className=" sim11 p-1">

</p>
<h2 className="mt-4 sim12">Years Experience</h2>

          </div>


        </div>

      </div>
      <div className="container-fluid">

        <div className="row mt-5">
        <div className="col-lg-1">
            &nbsp;

          </div>
          <div className="col-lg-2 mt-4">
            <h6>OUR AWESOME
CLIENTS</h6>

          </div>
         
          <div className="col-lg-9">
            <img src={finance}/>
            <img src={bsnl}/>
            <img src={mahe}/>
            <img src={nissan}/>
            <img src={tata}/>

          </div>

        </div>
      </div>

      <div className="container-fluid  mt-4">
      
        <div className="row bg-dark  san3 p-5">
        <div className="col-lg-12 d-flex flex-column align-items-center ">
        <h3 className=" text-warning mb-3">ADDRESS</h3> 
     <p >96 Gandhiji Street,<br/>
      Anthiyur,<br/>
      Coimbatore 638601 (TN)</p>
        </div>
        </div>
        </div>
































    {/* <div className="container-fluid">
        <div className="row">
        <div className="col-lg-12">
        <img src={img_1} className="img-fluid" alt="pic" id="roof1"/>
        <p> We manufacturing <br/>
        industrial steel <br/>
         roof top sheet</p>
        <h2>Learn more</h2>
        <h1>OUR SERVICES</h1>
        </div>
        </div>
        
    </div> */}
    {/* <div className="grid">
     <div><img src={wall} alt="cadding"/>
     <h1> WALL CADDING</h1>
     </div>
     <div><img src={zin} alt="zin"/>
     <h1> ZINCALUME</h1>
     </div>
     <div><img src={smart} alt="smart"/>
     <h1> SMART BUILDING</h1>
     </div>
     <div><img src={structure} alt="building"/>
     <h1>STRUCTURAL SOLUTION</h1>
     </div>
     <div><img src={butler} alt="butler"/>
      <h1>BUTLER BUILDING</h1>
     </div>
     <div><img src={coated} alt="cadding"/>
     <h1>COLOUR COATED SHEET</h1>
     </div>
    </div>
    <h3 id="menu">VIEW ALL SERVICES</h3>
    <p id="about">Hari Om Roofing Industries is one of the leading and the largest private<br/>
     sector producer of roofing sheets in India.</p>
     
    <p id="description">
    <h1>Why Choose Us</h1><br/>
    Hari Om Roofing Industries foundied by chairman Mr H Harish.<br/>
    Our range of pre-engineered buildings, roofing, cladding and rainwater<br/>
    systems, made from raw materials of the highest quality in accordance<br/>
    with strict ASTM / JIS / AS and IS standards, offers flexibility of design to<br/>
    suit any construction need be it your business, factory, home or office.<br/>
    Our products are made to meet your aesthetic needs as well.</p> 
    <h1 id="project">OUR PROJECTS</h1>

    
    <div className="grid_1">
     <div><img src={solar} alt="solar"/>
     <h4> SOALR FRAME</h4>
     </div>
     <div><img src={sheet} alt="sheet"/>
     <h4> GI DECKING SHEET</h4>
     </div>
     <div><img src={ventilator} alt="ventilator"/>
     <h4> ROOF VENTILATOR</h4>
     </div>
     <div><img src={sheets} alt="sheets"/>
     <h4>CURVED ROOFING SHEETS</h4>
     </div>
     <div><img src={cad} alt="cad"/>
      <h4>WALL CLADDING</h4>
     </div>
    </div>
    <h1 id="topic">TESTIMONIALS</h1><br/>
    <div className="footer">
    <div>
    <p>Many thanks for your roofing system which saved my customers at<br/>
     parking.we appreciate your quality concern methods!</p>
     </div>
     <div>
     <p>I am pleased with your dedicated work for building roof for my garage<br/>
     I surely recommend you to my friend who is in plan  to</p>
     </div>
     </div>
     <div id="no">
     <div>1100+</div>
     <div>2000+</div>
     <div>10+</div>
     </div>
     <div id="content">
     <div>Clients</div>
     <div>Project</div>
     <div>Years<br/> Experience</div>
     </div>
     <div id="companies">
     <div ><h1>OUR AWESOME <br/>CILENTS</h1></div>
     <div><img src={finance} alt="muthoot finannce"/></div>
     <div><img src={bsnl} alt="bsnl"/></div>
     <div><img src={mahe} alt="mahindra"/></div>
     <div><img src={nissan} alt="nissan"/></div>
     <div><img src={tata} alt="tata"/></div>
     </div>
     <div className="footer1">
     <div><h3>Unit I</h3> 
     <p>90/1 Adhavan Industrial Estate,<br/>
      Athipalayam Road, opp CRI pump,<br/>
      Chinnavedampatti post, Ganapathy,<br/>
      Coimbatore 641006 (TN)</p>
     </div>
     <div><h3>Unit II</h3>
     <p>Head Office<br/>
     90/2 Adhavan Industrial Estate,<br/>
     Athipalayam Road, opp CRI pump,<br/>
     Chinnavedampatti post, Ganapathy,<br/>
     Coimbatore 641006 (TN)</p>
     </div>
     <div><h3>Unit III</h3>
    <p>81/1 Adhavan Industrial Estate,<br/>
      Athipalayam Road, opp CRI pump,<br/>
     Chinnavedampatti post, Ganapathy,<br/>
      Coimbatore 641006 (TN)</p>
     </div>
     <div><h3>Unit IV</h3>
     <p>4/147 Senthampalayam,<br/>
      Masagoundenchettipalayam Post,<br/>
      Coimbatore 641107 (TN)</p></div>
     </div> */}
    </>

    )
    }
 export default Home;