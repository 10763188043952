import React from "react"
import "./contactus.css"
import { MdLocationOn } from "react-icons/md";
// import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { MdMessage } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
function Contactus() {

  const address = '96 Gandhiji Street, Anthiyur, Coimbatore 638601 (TN)';
  const formattedAddress = encodeURIComponent(address);

  const openGoogleMaps = () => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`, '_blank');
  };


    return(
    <>

<div className="container-fluid">
<div className="row p-3">
<div className="col-lg-5">
  <h1 id="contact">Contact us</h1>
  <form action="https://formspree.io/f/xyyrzgqe" method="POST">
    <div className="form-group mt-3 mb-3">
      <label className="form-label" htmlFor="name">Name:</label>
      <input type="text" className="form-control" id="name" name="name" placeholder="Enter name" />
    </div>
    <div className="form-group mb-3">
      <label className="form-label" htmlFor="email">Email:</label>
      <input type="text" className="form-control" id="email" name="email" placeholder="Enter Email" />
    </div>
    <div className="form-group mb-3">
      <label className="form-label" htmlFor="subject">Subject:</label>
      <input type="text" className="form-control" id="subject" name="subject" placeholder="Enter Subject" />
    </div>
    <div className="form-group mb-3">
      <label className="form-label" htmlFor="phone">Phone:</label>
      <input type="text" className="form-control" id="phone" name="phone" placeholder="Enter Phone" />
    </div>
    <div className="form-group mb-3">
      <label className="form-label" htmlFor="comments">Comments:</label>
      <textarea className="form-control" id="comments" name="comments" rows="4" placeholder="Enter your comments"></textarea>
    </div>
    <button type="submit" className="btn btn-primary">Send</button>
  </form>
</div>





  <div className="col-lg-7 mt-5 ps-5 ">
    <div className="row ">
      <div className="col-lg-6 ">
     <a href="#" onClick={openGoogleMaps} className="icon2">
        <MdLocationOn id="icon1" className="mb-2 text-danger" />
        <p >96 Gandhiji Street,<br/>
      Anthiyur,<br/>
      Coimbatore 638601 (TN)</p>
      </a>

       
    
        </div>

        <div className="col-lg-6 ">
      <a href="https://wa.me/918973111444" target="_blank" className="icon2" rel="noopener noreferrer">
        <FontAwesomeIcon  icon={faWhatsapp} id="icon1" className="mb-2 text-success" />
        <p >+91 8973111444</p>
      </a>
    </div>

    </div>
    
    
  <div className="row mt-5">
    <div className="col-lg-6">
        <MdOutlineWifiCalling3 id="icon1" className="mb-2 text-secondary"/>
        <p>+91 422-4274204</p>
    </div>
    
    <div  className="col-lg-6">
        <MdMessage id="icon1" className="mb-2 text-primary"/>
        <p>sathish@skengineerss.in
        </p>
    </div>
    </div>

    <div className="row text-end ">

      <div className="col-lg-12 mt-5 ">
      <a href="https://wa.me/918973111444" target="_blank"  rel="noopener noreferrer">
        <FontAwesomeIcon  icon={faWhatsapp} id="icon1" className="mt-5 text-success" style={{fontSize:"80px"}} />
      </a>
      </div>
    </div>
</div>

</div>

<div className="row bg-dark  san3 p-5">
        <div className="col-lg-12 d-flex flex-column align-items-center ">
        <h3 className=" text-warning mb-3">ADDRESS</h3> 
     <p >96 Gandhiji Street,<br/>
      Anthiyur,<br/>
      Coimbatore 638601 (TN)</p>
        </div>
        </div>
      
</div>
    





















































  
    
   
    </>)
     
}
 export default Contactus;