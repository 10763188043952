import React from "react"
import "./product.css"
import img_1 from "../images/img21.jpg";
import img_2 from "../images/img22.jpg";
import img_3 from "../images/img23.jpg";
import img_4 from "../images/img24.jpg";
import img_5 from "../images/img25.jpg";
import img_6 from "../images/img26.jpg";
import img_7 from "../images/img27.jpg";
import img_8 from "../images/img28.jpg";
import img_9 from "../images/img29.jpg";

function Product() {

    return(<>

<div className="container bg1">
  <div className="row bg1 text-center p-5 san4">
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_1} alt="img1" style={{width:"300px",height:"290px"}}/>
     <h2 className="mt-2">COMPACT MULTIWALL</h2>
 </div>
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_2} alt="img1" style={{width:"300px",height:"290px"}}/>
     <h2 className="mt-2" >POLYCARONNATE SHEETS</h2>
 </div>
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_3} alt="img1" style={{width:"300px",height:"300px"}}/>
     <h2>MODERN ROOF TILE</h2>
 </div>
  </div>



  <div className="row bg1 text-center p-5 san4">
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_4} alt="img1" style={{width:"300px",height:"290px"}}/>
     <h2 className="mt-2">COMPACT MULTIWALL</h2>
 </div>
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_5} alt="img1" style={{width:"300px",height:"290px"}}/>
     <h2 className="mt-2" >POLYCARONNATE SHEETS</h2>
 </div>
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_6} alt="img1" style={{width:"300px",height:"300px"}}/>
     <h2>MODERN ROOF TILE</h2>
 </div>
  </div>


  <div className="row bg1 text-center p-5 san4">
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_7} alt="img1" style={{width:"300px",height:"290px"}}/>
     <h2 className="mt-2">COMPACT MULTIWALL</h2>
 </div>
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_8} alt="img1" style={{width:"300px",height:"290px"}}/>
     <h2 className="mt-2" >POLYCARONNATE SHEETS</h2>
 </div>
 <div className="col-lg-4 bg1">
 <img className="img-fluid bg1 img" src={img_9} alt="img1" style={{width:"300px",height:"300px"}}/>
     <h2>MODERN ROOF TILE</h2>
 </div>
  </div>



</div>



<div className="container-fluid">
<div className="row bg-dark  san3 p-5">
        <div className="col-lg-12 d-flex flex-column align-items-center ">
        <h3 className=" text-warning mb-3">ADDRESS</h3> 
     <p >96 Gandhiji Street,<br/>
      Anthiyur,<br/>
      Coimbatore 638601 (TN)</p>
        </div>
        </div>

      </div>

   </>
    )
     
}
 export default Product