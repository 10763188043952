import React from "react"
import "./colour.css"
import sheet from "../images/ruffing.jpeg"
import colours from "../images/sheet1.jpeg"
import 'bootstrap/dist/css/bootstrap.min.css';
function Colourrange() {

    return(<>

<div className="container-fluid">
  <div className="row bg1 p-4 sancolor">
   <h1 className="text-center text-secondary">OUR MANUFACTURING SHEETS</h1>
  </div>
  <div className="row p-4 bg1">
    <div className="col-lg-6 bg1">
    <img className="img-fluid sanimg" src={sheet} alt="sheet"/>
    </div>
    <div className="col-lg-6 bg1">
    <img className="img-fluid sanimg" src={colours} alt="colours"/>
    </div>
  </div>

  <div className="row bg-dark  san3 p-5">
        <div className="col-lg-12 d-flex flex-column align-items-center ">
        <h3 className=" text-warning mb-3">ADDRESS</h3> 
     <p >96 Gandhiji Street,<br/>
      Anthiyur,<br/>
      Coimbatore 638601 (TN)</p>
        </div>
        </div>

</div>


















    </>
    )
     
}
 export default Colourrange