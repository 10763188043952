import React from "react"
import "./specificatio.css"
import img from "../images/coil.jpeg"
import 'bootstrap/dist/css/bootstrap.min.css';
function Specification() {

    return(<>

<div className="container-fluid bg">
 <div className="row bg button text-center mt-3">
  <div className="col-lg-4 bg p-4 ">
  <button type="button" class="btn san5 btn1">Colour Coated Coil</button>
  </div>
  <div className="col-lg-4 bg p-4"> 
  <button type="button" class="btn btn2 san5">Galvanished Plain Sheet</button>
  </div>
  <div className="col-lg-4 bg p-4">
  <button type="button" class="btn btn3 san5">Material Specifications</button>
  </div>

 </div>


 <div className="row bg para1 p-3 mt-3">
<div className="col-lg-6 bgli">
    <p>
Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form . Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form Coil coating is the most advanced techniques used in the continuous application of an organic coating on to a flat metal substrate in the coil form .
</p>
</div>
<div className="col-lg-6">
<img className="img-fluid" src={img} alt="img" id="coil"/>
</div>
 </div>


 <div className="row bg-dark  san3 p-5">
        <div className="col-lg-12 d-flex flex-column align-items-center ">
        <h3 className=" text-warning mb-3">ADDRESS</h3> 
     <p >96 Gandhiji Street,<br/>
      Anthiyur,<br/>
      Coimbatore 638601 (TN)</p>
        </div>
        </div>






</div>

























{/* 
    <div className="Specification">
     <div id="c1"></div>
     <div></div>
     <div></div>
    </div>
  
  
    <div className="footer5">
     <div><h3>Unit I</h3> 
     <p>90/1 Adhavan Industrial Estate,<br/>
      Athipalayam Road, opp CRI pump,<br/>
      Chinnavedampatti post, Ganapathy,<br/>
      Coimbatore 641006 (TN)</p>
     </div>
     <div><h3>Unit II</h3>
     <p>Head Office<br/>
     90/2 Adhavan Industrial Estate,<br/>
     Athipalayam Road, opp CRI pump,<br/>
     Chinnavedampatti post, Ganapathy,<br/>
     Coimbatore 641006 (TN)</p>
     </div>
     <div><h3>Unit III</h3>
    <p>81/1 Adhavan Industrial Estate,<br/>
      Athipalayam Road, opp CRI pump,<br/>
     Chinnavedampatti post, Ganapathy,<br/>
      Coimbatore 641006 (TN)</p>
     </div>
     <div><h3>Unit IV</h3>
     <p>4/147 Senthampalayam,<br/>
      Masagoundenchettipalayam Post,<br/>
      Coimbatore 641107 (TN)</p></div>
     </div> */}
    </>
    )
     
}
 export default Specification