import React from "react";
// import  "./About.css"
import "./about2.css"
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { BsFillSuitcaseLgFill } from "react-icons/bs"
import { HiMiniUserGroup } from "react-icons/hi2";
import { FaThumbsUp } from "react-icons/fa" 
import { FaLongArrowAltRight } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
function About() {
 
    return(<>
    <div className="container-fluid">
     <div className="row san">
<div className="col-lg-2">
&nbsp;
</div>
<div className="col-lg-8">
  <br/>
<h1 className="text-center mb-3">Welcome to SK ENGINEERING</h1>
<ol className="li1"> 
<li className="mb-3">SK ENGINEERING a Industries is one of the leading and the largest private sector producer of
 roofing sheets in India. Set up as Hari Om Roofing Industries foundied by chairman Mr P.SATHISH KUMAR</li>
<li className="mb-3"> Our range of pre-engineered buildings, roofing, cladding and rainwater systems, made from
raw materials of the highest quality in accordance with strict ASTM / JIS / AS and IS standards
, offers flexibility of design to suit any construction need be it your business, factory, home or
 office. Our products are made to meet your aesthetic needs as well.</li>
<li className="mb-3">Technology and innovation have always been the cornerstones of IIL's quest for excellence
and these state-of-the-art plants facilitate the company's mission to attain and sustain marke
 leadership, through technological and product superiority.</li>
<li className="mb-3">The company's strengths lie in its integrated process management
, knowledge management and control systems. And its seamless supply chain management systems further the efficient
use of raw materials, while its staff of highly skilled engineers, technicians and managers with
 specialised domain knowledge, ensure the choice of the relevant technology and the ability 
 to produce international quality products at a competitive price.</li>
<li className="mb-3">While we proudly display the sign in our
offices, on our trucks and throughout all of our corporate communications, it is important to know that the
 impact of the sign goes beyond the visual aesthetics. In fact, the sign itself is
  quite aged, and not fairly impressive, but what is strong about it is what it communicates about our organization.</li>
</ol>
</div>
<div className="col-lg-2">
&nbsp;
</div>
     </div>


     <div className="row bg1">
      <div className="col-lg-12 text-center">
      <button type="button" class="btn btn-outline-dark btn-lg p-3 san2 mt-3 mb-3">Our Services</button>
      </div>
      </div>


      <div className="row bg1 bg2 ">
        <div className="col">
        <h1 className="text-center text-white mt-2 mb-3">Always Good Work, More Than Just A Sign</h1>
        </div>
      </div>


      <div className="row bg1 text-center icon p-5">
        <div className="col-lg-2 bg1 ">
       <IoIosArrowDropdownCircle />
       <h3>OUR PROMISE</h3>
        </div>
        <div className="col-lg-1 bg1 ">
        <FaLongArrowAltRight />
        </div>
        <div className="col-lg-2 bg1 ">
        <BsFillSuitcaseLgFill />
      <h3>OUR LEGACY</h3>
        </div>
        <div className="col-lg-1 bg1  ">
        <FaLongArrowAltRight />
        </div>
        <div className="col-lg-2 bg1  ">
        <HiMiniUserGroup />
      <h3>OUR PRIDE</h3>
        </div>
        <div className="col-lg-1 bg1 ">
        <FaLongArrowAltRight />
        </div>
        <div className="col-lg-3 bg1  ">
        <FaThumbsUp />
      <h3>OUR MOTIVATION</h3>
        </div>
      </div>

      <div className="row bg-dark  san3 p-5">
        <div className="col-lg-12 d-flex flex-column align-items-center ">
        <h3 className=" text-warning mb-3">ADDRESS</h3> 
     <p >96 Gandhiji Street,<br/>
      Anthiyur,<br/>
      Coimbatore 638601 (TN)</p>
        </div>
        </div>


    


    </div>

    </>)
     
 }
 export default About