import React from "react";
import { BrowserRouter as Router, Route,Routes,  Link } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
// import "./App.css";
import "./pages/app2.css"
import logo from "./images/pic.jpeg"
import Product from "./pages/Product";
import Specification from "./pages/Specification";
import Colourrange from "./pages/Colourrange";
import Contactus from "./pages/Contactus";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() { 
  
  return(

      
    <Router>

<nav class="navbar navbar-expand-lg bg-dark navbar-dark ">
  <div class="container">

  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
      <span class="navbar-toggler-icon"></span>
    </button>

    <img src={logo} alt="Logo" class="rounded-pill navbar-brand img-fluid" style={{ width: "150px" }} />

    

    <div className="collapse navbar-collapse   li1" id="navbarNav">
      <ul class="navbar-nav ">
        <li class="nav-item ps-5">
          <Link className="nav-link text-white" to="/">Home</Link>
        </li>
        <li class="nav-item ps-5">
          <Link className="nav-link text-white" to="/about">About</Link>
        </li>
        <li class="nav-item ps-5">
          <Link className="nav-link text-white" to="/product">Product</Link>
        </li>
        <li class="nav-item ps-5">
          <Link className="nav-link text-white" to="/specification">Specification</Link>
        </li>
        <li class="nav-item ps-5">
          <Link className="nav-link text-white" to="/colourrange">Colour range</Link>
        </li>
        <li class="nav-item ps-5">
          <Link className="nav-link text-white" to="/contactUs">Contact us</Link>
        </li>
      </ul>
    </div>
  </div>
</nav>


       <Routes>
           
          <Route exact path="/" element={<Home/>}>
          </Route>
          <Route path="/about" element={<About/>}>
          </Route>
          <Route path="/product" element={<Product/>}>
          </Route>
          <Route path="/specification" element={<Specification/>}>
          </Route>
          <Route path="/colourrange" element={<Colourrange/>}>
          </Route>
          <Route path="/contactus" element={<Contactus/>}>
          </Route>
          </Routes>
    </Router> 

  );
}

export default App;